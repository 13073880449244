#masthead, #site-navigation {
    position: relative
}

.site-title {
    position: relative;
    float: left;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    margin: .67em 0 .2em
}

p.site-title {
    font-size: 2em;
    margin: .7em 0 .2em;
    line-height: 1.4
}

.site-title a {
    color: #1f2833;
    font-weight: 400;
    line-height: .8;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: .5em;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    text-decoration: none
}

.site-title a:hover {
    text-decoration: none
}

.site-title a span {
    font-size: 4.2em;
    display: block;
    letter-spacing: initial;
    line-height: .4;
    text-indent: -3px;
}

.site-title:after {
    content: " ";
    background: url(//citejournal.org/wp-content/themes/cite/images/logo.png) no-repeat 0 0;
    background-size: 100%;
    width: 53px;
    height: 77px;
    display: inline-block;
    position: absolute;
    margin: -1.35em 0 0;
}

/* Main Navigation */
.main-navigation {
    font-family: Cabin,Helvetica,Arial,sans-serif;
    float: right;
    width: auto;
    margin-top: -2.5em;
    margin-bottom: .25em;
    clear: both;
    display: block;
}

.main-navigation ul {
    list-style: none;
    margin: 0;
    padding-left: 0
}

.main-navigation li {
    float: left;
    position: relative
}

.main-navigation a {
    color: #616161;
    padding: .25em .5em
}

.menu-toggle,.main-navigation.toggled ul {
    display: block
}

#page {
    width: 100%;
    max-width: 1200px;
    margin: auto; 
    padding: .5em 2%;
}