div {
    display: block;
}

.clear:before,.clear:after,.entry-content:before,.entry-content:after,.comment-content:before,.comment-content:after,.site-header:before,.site-header:after,.site-content:before,.site-content:after,.site-footer:before,.site-footer:after {
    content: "";
    display: table;
    table-layout: fixed
}

.clear:after,.entry-content:after,.comment-content:after,.site-header:after,.site-content:after,.site-footer:after {
    clear: both
}

html {
    overflow-x: hidden
}

body {
    overflow-x: hidden;
    background-color: #ebebeb;
    color: #1f2833
}

.cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
}