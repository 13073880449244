.card {
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    transition: 0.3s;
    border-radius: 25px; /* 5px rounded corners */
    width: 100%;
    
    margin-top: 20px;
    margin-bottom: 20px;
}

#more {
    display: none;
}

#img {
    box-sizing: border-box;

    left: 0%;
    right: 0%;
    top: 50%;
    bottom: 52.5%;

    border-bottom: 1px solid #000000;
    border-radius: 25px 25px 0px 0px;

    width: 100%;
    height: 285px;
}

.card-desc{
    padding: 10px; 
}