#img-single {
    box-sizing: border-box;

    left: 0%;
    right: 0%;
    top: 50%;
    bottom: 52.5%;

    width: 330px;
    height: 313.5px;
    margin-right: 20px;
    
}

#details {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
}

#box {
    /* border-color: black; */
    border-style:solid;
    height: auto;
    padding: 10px;
    margin-left: 20px;
}

#link {
    text-decoration: none;
    color: black;
}

.detail {
    margin-left: 14px;
}