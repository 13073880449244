#engineering-banner{
    background-color: #fab040;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

#science-banner{
    background-color:#506f86;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

#technology-banner {
    background-color: #895d2e;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

#mathematics-banner {
    background-color: #de703c;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

#home-banner {
    background-color:  #2f3c4f;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

#forum-banner {
    background-color:  #8dadc4;
    font-size:18px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Cabin,Helvetica,Arial,sans-serif;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}

.background-forum{
    background-color:  #2092e7;
}

.container {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
}